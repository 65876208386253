import React, { useState, useEffect, useRef } from "react";
import {
  getContactList,
  getContactStatus,
} from "../../../service/Contacts.service";
import { CONST } from "../../../consts/consts";
import NeoTooltip from "../../../design/design_components/neo/overlay/NeoTooltip.base";
import { dateTimeTemplate } from "../../../utils/date.utils";
import NeoRangeNumberFilter from "../../../design/design_components/neo/table/NeoRangeNumberFilter.base";
import NeoDateRangeFilter from "../../../design/design_components/neo/table/NeoDateRangeFilter.base";
import NeoMultiSelectFilter from "../../../design/design_components/neo/table/NeoMultiSelectFilter.base";
import NeoTable from "../../../design/design_components/neo/table/NeoTable.base";
import NeoTableColumn from "../../../design/design_components/neo/table/NeoTableColumn.base";
import NeoInputTextFilter from "../../../design/design_components/neo/table/NeoInputTextFilter.base";
import useToastContext from "../../../hooks/useToastContext.hook";
import Routes from "../../../routers/Routes.router.json";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";

export default function ContactsTable() {
  const ROWS_COUNT = 10;
  const EMAIL_VALIDATIONS = CONST.EMAIL_VALIDATIONS;
  const EMAIL_VALIDATION_COLLECTIONS = Object.entries(EMAIL_VALIDATIONS).map(
    (type) => type[1]
  );
  const [contactStatusCollection, setContactStatusCollection] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [contacts, setContacts] = useState([]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: ROWS_COUNT,
    page: 1,
  });

  const toast = useToastContext();
  const dt = useRef(null);
  const history = useHistory(null);

  useEffect(() => {
    let mounted = true;

    setLoading(true);
    if (mounted) {
      getContactStatusCollection();
    }
    setLoading(false);
    return () => {
      mounted = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      loadLazyData();
    }
    return () => {
      mounted = false;
    };
  }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLazyParams((prev) => ({
      ...prev,
      sortField: "LAST_CONNECTION",
      sortOrder: -1,
    }));
  }, []);

  const getContactStatusCollection = async () => {
    let contactsStatusCollection;
    contactsStatusCollection = await getContactStatus();
    if (!contactsStatusCollection.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
      setContactStatusCollection([]);
      console.log(contactsStatusCollection);
    } else {
      setContactStatusCollection(
        contactsStatusCollection.result.contactStatus.map((status) => ({
          label: status.STATUS_NAME,
          value: status.STATUS_NAME,
        }))
      );
    }
  };

  const loadLazyData = async () => {
    setLoading(true);
    let contactsData;

    contactsData = await getContactList(lazyParams);
    if (!contactsData.success) {
      return console.log(contactsData);
    }
    setTotalRecords(contactsData.result.totalRecords);
    setContacts(contactsData.result.contacts);
    setLoading(false);
  };

  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };

  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };

  const onFilter = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    _lazyParams["first"] = 0;
    setLazyParams(_lazyParams);
  };

  const emailBodyTemplate = (email) => {
    return (
      <>
        <span className="p-column-title"> Correo electrónico</span>
        {email}
      </>
    );
  };

  const validationBodyTemplate = (validation) => {
    switch (validation) {
      case EMAIL_VALIDATIONS.valid.value:
        return <>{EMAIL_VALIDATIONS.valid.label}</>;
      case EMAIL_VALIDATIONS.invalid.value:
        return <>{EMAIL_VALIDATIONS.invalid.label}</>;
      case EMAIL_VALIDATIONS.risky.value:
        return <>{EMAIL_VALIDATIONS.risky.label}</>;

      case EMAIL_VALIDATIONS.noValid.value:
        return <>{EMAIL_VALIDATIONS.noValid.label}</>;

      default:
        return <>{EMAIL_VALIDATIONS.unknown.label}</>;
    }
  };

  const lastConnectionBodyTemplate = (lastConnection) => {
    return (
      <>
        <span className="p-column-title"> Última conexión</span>
        {Intl.DateTimeFormat("es-MX", {
          month: "long",
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
          year: "numeric",
          day: "numeric",
        }).format(moment(lastConnection).toDate())}
      </>
    );
  };

  const visitsCountBodyTemplate = (visitsCount) => {
    return (
      <>
        <span className="p-column-title">Cantidad de visitas</span>
        {visitsCount}
      </>
    );
  };

  const avgVisitBodyTemplate = (avgVisit) => {
    return <span>{(avgVisit / 3600).toFixed(1)} hrs</span>;
  };

  const convertToSeconds = (value) => {
    if (value === null) {
      return null;
    }
    return value * 3600;
  };

  const emailVailidationFilterElement = () => {
    return (
      <NeoMultiSelectFilter
        ref={dt}
        options={EMAIL_VALIDATION_COLLECTIONS}
        field={"EMAIL_VALIDATION"}
        placeholder="Todos"
        selectedItemsLabel="{0} tipos de validaciones"
      />
    );
  };

  const statusFilterElement = () => {
    return (
      <NeoMultiSelectFilter
        ref={dt}
        options={contactStatusCollection}
        field={"CONTACT_STATUS.STATUS_NAME"}
        placeholder="Todos"
        selectedItemsLabel="{0} tipos de estatus"
      />
    );
  };

  const avgDurationFilterElement = () => {
    return (
      <NeoRangeNumberFilter
        ref={dt}
        field={"VISITS.AVG_DURATION"}
        format={convertToSeconds}
        minPlaceholder={"Mín"}
        maxPlaceholder={"Máx"}
        minDigit={1}
        maxDigit={1}
      />
    );
  };

  const visitFilterElement = () => {
    return (
      <NeoRangeNumberFilter
        ref={dt}
        field={"VISITS.VISIT_COUNT"}
        minPlaceholder={"Mín"}
        maxPlaceholder={"Máx"}
        minDigit={0}
        maxDigit={0}
      />
    );
  };

  const lastConnectionFilterElement = () => {
    return (
      <NeoDateRangeFilter
        ref={dt}
        field={"VISITS.LAST_CONNECTION"}
        placeholder={"Rango de fechas"}
        matchFilter="range"
      />
    );
  };

  const emailFilterElement = () => {
    return (
      <NeoInputTextFilter
        ref={dt}
        field={"EMAIL"}
        placeholder={"Buscar por correo"}
        filterMatch={"contains"}
      />
    );
  };

  const goToContactDetail = (contactData) => {
    const { ID } = contactData;
    history.push(`${Routes.contacts.detailsWithOutParams}/${ID}`, contactData);
  };

  const emailValidationQuestionMark = () => {
    return `
       ${EMAIL_VALIDATIONS.valid.label}: ${EMAIL_VALIDATIONS.valid.description}\n
        ${EMAIL_VALIDATIONS.invalid.label}: ${EMAIL_VALIDATIONS.invalid.description}\n
        ${EMAIL_VALIDATIONS.risky.label}: ${EMAIL_VALIDATIONS.risky.description}\n
        ${EMAIL_VALIDATIONS.unknown.label}:
        ${EMAIL_VALIDATIONS.unknown.description}\n
        ${EMAIL_VALIDATIONS.noValid.label}:
        ${EMAIL_VALIDATIONS.noValid.description}\n
      `;
  };

  return (
    <div>
      <div className="content-section implementation">
        <NeoTooltip target=".emailValidation" data-pr-showdelay={1000} />
        <div className="card">
          <NeoTable
            emptyMessage={"No hay contactos registrados"}
            ref={dt}
            value={contacts}
            lazy
            selectionMode="single"
            dataKey="ID"
            autoLayout={true}
            paginator
            first={lazyParams.first}
            rows={ROWS_COUNT}
            totalRecords={totalRecords}
            onPage={onPage}
            onSort={onSort}
            removableSort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onFilter={onFilter}
            filters={lazyParams.filters}
            loading={loading}
            onRowSelect={(e) => goToContactDetail(e.data)}
          >
            <NeoTableColumn
              field="EMAIL"
              header="Correo electrónico"
              filter
              sortable
              filterMatchMode="contains"
              body={(e) => emailBodyTemplate(e.EMAIL)}
              filterElement={emailFilterElement()}
            />
            <NeoTableColumn
              field="EMAIL_VALIDATION"
              header={
                <>
                  <NeoTooltip
                    target=".emailValidation"
                    data-pr-showdelay={1000}
                  />
                  Validación del correo
                  <i
                    className="pi pi-question-circle emailValidation"
                    data-pr-tooltip={emailValidationQuestionMark()}
                  ></i>
                </>
              }
              filter
              headerClassName=".emailValidation"
              sortable
              filterElement={emailVailidationFilterElement()}
              filterMatchMode="in"
              body={(e) => validationBodyTemplate(e.EMAIL_VALIDATION)}
            />
            <NeoTableColumn
              field="LAST_CONNECTION"
              header="Última conexión"
              filter
              sortable
              filterElement={lastConnectionFilterElement()}
              filterMatchMode="range"
              body={(e) => lastConnectionBodyTemplate(e.LAST_CONNECTION)}
            />
            {/* <NeoTableColumn
              field="AVG_DURATION"
              header="Tiempo promedio"
              filter
              sortable
              filterElement={avgDurationFilterElement()}
              filterMatchMode="range"
              body={(e) => avgVisitBodyTemplate(e.AVG_DURATION)}
            /> */}
            <NeoTableColumn
              field="VISIT_COUNT"
              header="Cantidad de visitas"
              filter
              sortable
              filterElement={visitFilterElement()}
              filterMatchMode="contains"
              body={(e) => visitsCountBodyTemplate(e.VISIT_COUNT)}
            />
            <NeoTableColumn
              field="STATUS_NAME"
              body={(e) => e.CONTACT_STATUS}
              header="Estatus"
              filter
              sortable
              filterElement={statusFilterElement()}
              filterMatchMode="contains"
            />
          </NeoTable>
        </div>
      </div>
    </div>
  );
}
