import React, { useState, useEffect, useRef } from "react";
import { getContactVisit } from "../../../service/Contacts.service";
import { CONST } from "../../../consts/consts";
import NeoTable from "../../../design/design_components/neo/table/NeoTable.base";
import NeoTableColumn from "../../../design/design_components/neo/table/NeoTableColumn.base";
import useToastContext from "../../../hooks/useToastContext.hook";
import { dateTimeTemplate } from "../../../utils/date.utils";
import NeoDateRangeFilter from "../../../design/design_components/neo/table/NeoDateRangeFilter.base";
import NeoRangeNumberFilter from "../../../design/design_components/neo/table/NeoRangeNumberFilter.base";
import NeoInputTextFilter from "../../../design/design_components/neo/table/NeoInputTextFilter.base";
import NeoRating from "../../../design/design_components/neo/form/NeoRating.base";

export default function ContactVisitsTable(props) {
  const ROWS_COUNT = 5;
  const [loadingTable, setLoadingTable] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [visits, setVisits] = useState([]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: ROWS_COUNT,
    page: 1,
  });

  const toast = useToastContext();
  const dt = useRef(null);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      loadLazyData();
    }
    return () => {
      mounted = false;
    };
  }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadLazyData = async () => {
    setLoadingTable(true);
    let visits;

    visits = await getContactVisit(lazyParams, props.userId);
    if (!visits.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
      return console.log(visits);
    }
    setVisits(visits.result.visitDetails);
    setTotalRecords(visits.result.totalRecords);
    setLoadingTable(false);
  };

  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };

  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };

  const onFilter = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    _lazyParams["first"] = 0;
    setLazyParams(_lazyParams);
  };

  const lastConnectionBodyTemplate = (lastConnection) => {
    return (
      <>
        <span className="p-column-title"> Fecha de conexión</span>
        {dateTimeTemplate(lastConnection)}
      </>
    );
  };

  const durationBodyTemplate = (duration) => {
    return <span>{(duration / 3600).toFixed(1)} hrs</span>;
  };

  const macAddressBodyTemplate = (macAddress) => {
    return (
      <>
        <span className="p-column-title"> Mac Address</span>
        {macAddress}
      </>
    );
  };

  const reviewComponentBody = (review) => {
    if (review) {
      return <NeoRating value={review} readOnly stars={5} cancel={false} />;
    }
    return null;
  };

  const visitFilterElement = () => {
    return (
      <NeoRangeNumberFilter
        ref={dt}
        field={"VISIT_DETAIL.REVIEW_STARS"}
        minPlaceholder={"Mín"}
        maxPlaceholder={"Máx"}
        minDigit={0}
        maxDigit={0}
      />
    );
  };

  const lastConnectionFilterElement = () => {
    return (
      <NeoDateRangeFilter
        ref={dt}
        field={"VISIT_DETAIL.LOGIN"}
        placeholder={"Rango de fechas"}
        matchFilter="range"
      />
    );
  };

  const convertToSeconds = (value) => {
    if (value === null) {
      return null;
    }
    return value * 3600;
  };

  const durationFilterElement = () => {
    return (
      <NeoRangeNumberFilter
        ref={dt}
        field={"VISIT_DETAIL.DURATION"}
        format={convertToSeconds}
        minPlaceholder={"Mínimo"}
        maxPlaceholder={"Máximo"}
        minDigit={1}
        maxDigit={1}
      />
    );
  };

  const macAddressFilterElement = () => {
    return (
      <NeoInputTextFilter
        ref={dt}
        field={"MAC_ADDRESS"}
        placeholder={"Buscar por mac address"}
        filterMatch={"contains"}
      />
    );
  };

  return (
    <div className="content-section implementation">
      <div className="card">
        <NeoTable
          ref={dt}
          emptyMessage={"No hay visitas registradas"}
          value={visits}
          lazy
          dataKey="ID"
          paginator
          first={lazyParams.first}
          rows={ROWS_COUNT}
          totalRecords={totalRecords}
          onPage={onPage}
          onSort={onSort}
          removableSort
          sortField={lazyParams.sortField}
          sortOrder={lazyParams.sortOrder}
          onFilter={onFilter}
          filters={lazyParams.filters}
          loading={loadingTable}
        >
          <NeoTableColumn
            field="CONNECTION_DATE"
            header="Fecha de conexión"
            body={(e) => lastConnectionBodyTemplate(e.CONNECTION_DATE)}
            filter
            filterElement={lastConnectionFilterElement()}
            filterMatchMode="range"
          />
          <NeoTableColumn
            field="MAC_ADDRESS"
            header="Mac Address"
            filter
            filterElement={macAddressFilterElement()}
            body={(e) => macAddressBodyTemplate(e.MAC_ADDRESS)}
          />
          <NeoTableColumn
            field="REVIEW_STARS"
            header="Calificación"
            filter
            filterElement={visitFilterElement()}
            filterMatchMode="contains"
            body={(e) => reviewComponentBody(e.REVIEW_STARS)}
          />
          {/* <NeoTableColumn
            field="DURATION"
            header="Tiempo conectado"
            filter
            filterElement={durationFilterElement()}
            filterMatchMode="range"
            body={(e) => durationBodyTemplate(e.DURATION)}
          /> */}
        </NeoTable>
      </div>
    </div>
  );
}
