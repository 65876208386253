import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Contacts from "../views/contacts/Contacts.view";
import Home from "../views/home/Home.view";
import Principal from "../views/home/Principal.view";
import Store from "../views/stores/Store.view";
import Routes from "../routers/Routes.router.json";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContextProvider } from "../contexts/ToastContext.context";
import StoreDetails from "../views/stores/StoreDetails.view";
import StoreHeatmap from "../views/stores/StoreHeatmap.view";
import ContactDetails from "../views/contacts/ContactDetails.view";
import SplashPages from "../views/splashPages/SplashPages.view";
import SplashPageDetails from "../views/splashPages/SplashPageDetails.view";
import ShippingConfiguration from "../views/deals/ShippingConfiguration.view";
import Settings from "../views/settings/Settings.view";
import { auth } from "../config/firebase.config";
import { logout } from "../contexts/useUserAuth.context";
import Shipping from "../views/deals/Shipping.view";
import PrivateRoute from "./components/PrivateRoute.component";
import { getUserStatus } from "../service/User.service";
import MailingList from "../views/mailing/MailingList.view";
import { actualUrl } from "../utils/sso.util";
import { CONST } from "../consts/consts";
import MediaList from "../views/media/MediaList.view";
import MediaAdmin from "../views/media/components/MediaAdmin.component";
import MediaStatistics from "../views/media/MediaStatistics.view";
import MediaStatisticsStore from "../views/media/MediaStatisticsStore.view";

export default function ClientRoutes() {
  const history = useHistory();
  const [deals, setDeals] = useState(null);
  const [menu, setMenu] = useState([]);

  const handleSignOut = async () => {
    await logout();

    if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
      history.push("/landingPage");
    } else if (process.env.REACT_APP_ENVIRONMENT === "TEST") {
      const urlLogout = new URL(CONST.TEST_CONSTS.URL_LOGOUT_TEST);
      urlLogout.searchParams.set("continue", actualUrl("/logintest"));
      window.location.replace(urlLogout);
    } else if (process.env.REACT_APP_ENVIRONMENT === "STAGING") {
      const urlLogout = new URL(CONST.STAGING_CONSTS.URL_LOGOUT_STAGING);
      urlLogout.searchParams.set("continue", actualUrl("/logintest"));
      window.location.replace(urlLogout);
    } else if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
      const urlLogout = new URL(CONST.PROD_CONSTS.URL_LOGOUT_PROD);
      urlLogout.searchParams.set("continue", actualUrl("/logintest"));
      window.location.replace(urlLogout);
    } else {
      history.push("/");
    }
  };

  useEffect(() => {
    (async () => {
      const userStatus = await getUserStatus();
      setDeals(userStatus.result);
    })();
  }, []);

  useEffect(() => {
    const menu = [];

    if(deals) {
      if(deals.apsConfigured) {
        menu.push({
          label: "Estadísticas",
          redirect: Routes.statistics.home,
          command: () => {
            history.push({
              pathname: Routes.statistics.home,
            });
          },
        });
        menu.push({
          label: "Contactos",
          redirect: Routes.contacts.home,
          command: () => {
            history.push({
              pathname: Routes.contacts.home,
            });
          },
        });
        menu.push({
          label: "Sucursales",
          redirect: Routes.stores.home,
          command: () => {
            history.push({
              pathname: Routes.stores.home,
            });
          },
        });
        menu.push({
          label: "Splash pages",
          redirect: Routes.splashPages.home,
          command: () => {
            history.push({
              pathname: Routes.splashPages.home,
            });
          },
        });
        menu.push({
          label: "Notificaciones",
          redirect: Routes.mailing.home,
          command: () => {
            history.push({
              pathname: Routes.mailing.home,
            });
          },
        });

        switch (auth.currentUser?.email) {
          case "pizzahut@neomkt.com":
          case "tiendas3b@neomkt.com":
          case "liverpool@neored.info":
            menu.push({
              label: "Anuncios",
              redirect: Routes.media.home,
              command: () => {
                history.push({
                  pathname: Routes.media.home,
                });
              },
            });
            break;
          default:
            break;
        }

        menu.push({
          label: "Configuración",
          redirect: Routes.settings.home,
          command: () => {
            history.push({
              pathname: Routes.settings.home,
            });
          },
        });
      }
  
      menu.push({
        label: auth.currentUser.email,
        className: "signout",
        command: async () => handleSignOut(),
      });
    }

    setMenu(menu);
  }, [deals])

  return (
    <Principal menu={menu}>
      <ToastContextProvider>
        <Switch>
          <PrivateRoute path={"/estadisticas"} component={Home} />
          <PrivateRoute
            exact
            path={Routes.contacts.home}
            component={Contacts}
          />
          <PrivateRoute
            exact
            path={Routes.contacts.details}
            component={ContactDetails}
          />
          <PrivateRoute exact path={Routes.stores.home} component={Store} />
          <PrivateRoute
            exact
            path={Routes.stores.details}
            component={StoreDetails}
          />
          <PrivateRoute
            exact
            path={Routes.stores.heatmap}
            component={StoreHeatmap}
          />
          <PrivateRoute
            exact
            path={Routes.splashPages.home}
            component={SplashPages}
          />
          <PrivateRoute
            exact
            path={Routes.splashPages.details}
            component={SplashPageDetails}
          />
          <Route
            exact
            path={Routes.deals.shippingConfiguration}
            component={ShippingConfiguration}
          />
          <Route exact path={Routes.deals.shipping} component={Shipping} />
          <PrivateRoute
            exact
            path={Routes.settings.home}
            component={Settings}
          />
          <PrivateRoute
            exact
            path={Routes.mailing.home}
            component={MailingList}
          />
          <PrivateRoute exact path={Routes.media.home} component={MediaList} />
          <PrivateRoute
            exact
            path={Routes.media.newMedia}
            component={MediaAdmin}
          />

          <PrivateRoute
            exact
            path={Routes.media.modifyMedia}
            component={MediaAdmin}
          />

          <PrivateRoute
            exact
            path={Routes.media.statistic}
            component={MediaStatistics}
          />
          <PrivateRoute
            exact
            path={Routes.media.statisticStore}
            component={MediaStatisticsStore}
          />
          <Route path="*" component={() => <Redirect to={Routes.statistics.home} />}/>
        </Switch>
      </ToastContextProvider>
    </Principal>
  );
}
