
import { useLookerUrl } from "../../hooks/useLookerUrl.hook";

const ContactsView = () => {
  const { url } = useLookerUrl("neowif_test::contactos")
  return (
        <iframe
          title="Contactos"
          src={url}
          allowFullScreen
        />
  )
}

export default ContactsView;
