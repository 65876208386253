import { useEffect } from "react";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoColumn from "../../design/design_components/neo/layout/NeoColumn.base";
import { Menu } from 'primereact/menu';
import { Route, Switch, Redirect, useHistory, useLocation } from "react-router-dom";
import PrivateRoute from "../../routers/components/PrivateRoute.component";
import Routes from "../../routers/Routes.router.json";
import General from "./General.view";
import Marketing from "./Marketing.view";
import Stores from "./Stores.view";
import Activity from "./Activity.view";
import SocialNetworks from "./SocialNetworks.view";
import Contacts from "./Contacts.view";
import NeoTitleMain from "../../design/design_components/neo/title/NeoTitleMain.base";
import Icono from "../../design/assets/img/mnv/icons/icon-dashboard.svg";

export default function Home() {
  const history = useHistory();
  const location = useLocation();

  const items = [
    {
      label: 'General',
      icon: 'fa fa-chart-line',
      className: location.pathname === Routes.statistics.home ? "active" : '',
      command: () => {
        history.push({
          pathname: Routes.statistics.home,
        });
      }
    },
    {
      label: 'Marketing',
      icon: 'fa fa-bullhorn',
      className: location.pathname === Routes.statistics.marketing ? "active" : '',
      command: () => {
        history.push({
          pathname: Routes.statistics.marketing,
        });
      }
    },
    {
      label: 'Sucursales',
      icon: 'fa fa-store',
      className: location.pathname === Routes.statistics.stores ? "active" : '',
      command: () => {
        history.push({
          pathname: Routes.stores.home,
        });
      }
    },
    {
      label: 'Actividad',
      icon: 'fa fa-running',
      className: location.pathname === Routes.statistics.activity ? "active" : '',
      command: () => {
        history.push({
          pathname: Routes.statistics.activity,
        });
      }
    },
    {
      label: 'Redes Sociales',
      icon: 'fa fa-users',
      className: location.pathname === Routes.statistics.socialNetworks ? "active" : '',
      command: () => {
        history.push({
          pathname: Routes.statistics.socialNetworks,
        });
      }
    },
    {
      label: 'Contactos',
      icon: 'fa fa-address-book',
      className: location.pathname === Routes.statistics.contacts ? "active" : '',
      command: () => {
        history.push({
          pathname: Routes.statistics.contacts,
        });
      }
    },
    // {
    //   label: 'Encuestas',
    //   icon: 'fa fa-comment',
    //   className: location.pathname === Routes.statistics.surveys ? "active" : '',
    //   command: () => {
    //     history.push({
    //       pathname: Routes.statistics.surveys,
    //     });
    //   }
    // },
  ];

  // useEffect(() => {
  //   const container = document.querySelector('.main-container');
  //   container.classList.add("fluid");
  //   return () => {
  //     container.classList.remove("fluid");
  //   }
  // }, [])

  return (
    <div className="statistics-main-container">
          <div className="statistics-menu-container">
            <div>
              <NeoTitleMain title="Estadísticas" icon={Icono} />
              <Menu className="menu-statistics" model={items} />
            </div>
            <div className="vertical-divider">
            
            </div>
          </div>

          <div className="looker-container">
            <Switch>
              <PrivateRoute
                exact
                path={Routes.statistics.home}
                component={General}
              />
              <PrivateRoute
                exact
                path={Routes.statistics.marketing}
                component={Marketing}
              />
              <PrivateRoute
                exact
                path={Routes.statistics.stores}
                component={Stores}
              />
              <PrivateRoute
                exact
                path={Routes.statistics.activity}
                component={Activity}
              />
              <PrivateRoute
                exact
                path={Routes.statistics.contacts}
                component={Contacts}
              />
              <PrivateRoute
                exact
                path={Routes.statistics.socialNetworks}
                component={SocialNetworks}
              />
              <Route path="*" component={() => <Redirect to={Routes.statistics.home} />} />
            </Switch>
          </div>

    </div>
  );
}
