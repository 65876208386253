import React, { useState } from 'react';
import NeoMultiSelect from '../form/NeoMultiSelect.base'

const NeoMultiSelectFilter = (props, ref) => {

  const [elementSelected, setElementSelected] = useState(null);

  const onElementSelectedChange = (e) => {
    ref.current.filter(e.value, props.field, props.matchFilter ?? "in")
    setElementSelected(e.value)
  };
  
  return ( 
    <NeoMultiSelect value={elementSelected} options={props.options} 
      onChange={onElementSelectedChange} placeholder={props.placeholder} 
      selectedItemsLabel={props.selectedItemsLabel} 
      itemTemplate={props.itemTemplate ?? ((option) => (
        <div className="p-multiselect-representative-option">
            <span className="image-text">{option.label}</span>
        </div>
      ))}
      className="p-column-filter"
    />
  )
}

export default React.forwardRef(NeoMultiSelectFilter);
