import { useLookerUrl } from "../../hooks/useLookerUrl.hook";

const MarketingView = () => {
  const { url } = useLookerUrl("neowif_test::marketing")
  return (
    <iframe
          title="Dashbaord General"
          src={url}
          allowFullScreen
        />
  )
}

export default MarketingView;
