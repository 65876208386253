import NeoColumn from "../../design/design_components/neo/layout/NeoColumn.base";
import { useLookerUrl } from "../../hooks/useLookerUrl.hook";

const SocialNetworksView = () => {
  const { url } = useLookerUrl("neowif_test::redes_sociales")
  return (
    <iframe
          title="Dashbaord General"
          src={url}
          allowFullScreen
        />
  )
}

export default SocialNetworksView;
