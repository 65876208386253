import React from 'react'

import NeoHeader from '../../design/design_components/neo/layout/NeoHeader.base'
import NeoNavBar from '../../design/design_components/neo/menu/NeoNavBar.base'
import NeoMainContainer from '../../design/design_components/neo/layout/NeoMainContainer.base'
import Logo from "../../design/assets/img/wfi/logo.svg";

export default function Principal(props) {
  return (
    <>
      <NeoHeader>
        <NeoNavBar
          items={props.menu}
          logo={Logo}
          extra="navbar-principal blue-nav p-jc-between"
        />
      </NeoHeader>
      <NeoMainContainer>{props.children}</NeoMainContainer>
    </>
  );
}
