import { useEffect, useState } from "react";
import { getLookerUrl } from "../service/Looker.service"

export function useLookerUrl(dashboard = "neowif_test::dashboard_principal") {
    const [url, setUrl] = useState(null);

    useEffect(() => {
        (async () => {
            const resp = await getLookerUrl(dashboard);

            if (!resp.success) {
                console.error("No se generó correctamente la URL", resp.error);
                return;
            }

            const { url } = resp.payload;
            setUrl(url);
        })();
    }, []);

    return { url };
}
