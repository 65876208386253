import React, { useEffect, useRef, useState } from "react";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleSecond from "../../design/design_components/neo/title/NeoTitleSecond.base";

import Icono from "../../design/assets/img/wfi/icons/icon-branch-3.svg";

import { useParams } from "react-router-dom";
import NeoCard from "../../design/design_components/neo/panel/NeoCard.base";
import NeoDropdown from "../../design/design_components/neo/form/NeoDropdown.base";
import NeoCalendar from "../../design/design_components/neo/form/NeoCalendar.base";

import {
  getStoreDetail,
  getStoreFloors,
  getStoreHeatmap,
  getStoreHeatmapDuration,
  getStoreTrajectory
} from "../../service/Store.service"
import { CONST } from "../../consts/consts";
import useToastContext from "../../hooks/useToastContext.hook";
import NeoTitleMain from "../../design/design_components/neo/title/NeoTitleMain.base";
import moment from "moment-timezone";

import NeoTabMenu from "../../design/design_components/neo/menu/NeoTabMenu.base";

const colors = [
  { border: "hsla(118, 34%, 45%, 1)", background: "hsla(118, 34%, 45%, .5)" },
  { border: "hsla(48, 90%, 69%, 1)", background: "hsla(48, 90%, 69%, .5)" },
  { border: "hsla(30, 89%, 67%, 1)", background: "hsla(30, 89%, 67%, .5)" },
  { border: "hsla(357, 89%, 64%, 1)", background: "hsla(357, 89%, 64%, .5)" },
];

const zeroPad = (num, places) => String(num).padStart(places, "0");

const ComponentFloorPlan = ({floor}) => {
  return (<>
    {floor?.URL_PLAN && 
      <img
        key={1}
        alt="mapa"
        className="heatmap-map"
        src={floor.URL_PLAN}
      />
    }
  </>)
}

export default function StoreHeatmap() {
  const params = useParams();
  const toast = useToastContext();
  const canvasRef = useRef();

  const now = new Date();
  const [dateRange, setDateRange] = useState({
    start: moment(now).subtract(1, "hour").toDate(),
    end: moment(now).add(10, "minutes").toDate(),
  });
  const [floor, setFloor] = useState();
  const [floors, setFloors] = useState([]);
  const [aps, setAps] = useState([]);
  const [trajectory, setTrajectory] = useState([]);
  const [storeDetail, setStoreDetail] = useState({
    ID: 0,
    STORE_NAME: "",
    SSID: "",
    ADDRESS: "No disponible",
  });

  const getStoreDetailData = async () => {
    let storeData;
    storeData = await getStoreDetail(params.id);

    if (!storeData.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
    } else {
      setStoreDetail((prevState) => ({
        ...prevState,
        ...storeData.result.storeDetail[0],
      }));
    }
  };

  const setApsColors = (aps, property = "connections") => {
    const max = aps.reduce((acc, ap) => {
      return Math.max(acc, ap[property]);
    }, 0);

    const apsWithColor = aps.map((ap) => {
      if (ap[property] === 0) {
        return { ...ap, border: "hsla(118, 34%, 45%, 1)", background: "hsla(118, 34%, 45%, .5)" };
      }
      const colorVal = (ap[property] / max) * (colors.length - 1);
      const colorIndex = Math.round(colorVal);
      return { ...ap, border: colors[colorIndex].border, background: colors[colorIndex].background };
    });

    return apsWithColor;
  };

  const getApsData = async () => {
    const heatmapData = await getStoreHeatmap(
      params.id,
      floor.ID,
      formatDatetime(dateRange.start),
      formatDatetime(dateRange.end)
    );
    if (heatmapData.error) {
      return;
    }
    const { payload: { heatmap = [] } = { heatmap: [] } } = heatmapData;
    return heatmap;
  };

  const getApsDuration = async () => {
    const heatmapDuration = await getStoreHeatmapDuration(
      params.id,
      formatDatetime(dateRange.start),
      formatDatetime(dateRange.end)
    );
    if (heatmapDuration.error) {
      return;
    }
    return aps.map((ap) => {
      ap.avgTime = heatmapDuration.payload?.rows?.find(
        (apBigQuery) => apBigQuery.ap_mac === ap.macAddress
      )?.avg_time ?? 0;
      return ap;
    });
  };

  const getFloorsData = async () => {
    const floorsData = await getStoreFloors(params.id);
    if (floorsData.error) {
      return;
    }
    setFloors(floorsData.payload?.floors);
    setFloor(floorsData.payload?.floors[0]);
  };

  const formatDatetime = (date) => {
    return Intl.DateTimeFormat("es-MX", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    })
      .format(date)
      .replace(",", "");
  };

  const [activeIndex, setActiveIndex] = useState(0);

  async function getTrajectoryData() {
    const trajectory = await getStoreTrajectory(
      params.id,
      floor.ID,
      formatDatetime(dateRange.start),
      formatDatetime(dateRange.end)
    );
    if (trajectory.error) {
      return;
    }
    return trajectory.payload?.rows[0]?.ruta?.split(',') ?? [];
  }

  useEffect(() => {
    getStoreDetailData();
    getFloorsData();
  }, []);

  useEffect(async () => {
    if (floor) {
      if (activeIndex === 0) {
        const aps = await getApsData();
        setAps(setApsColors(aps));
      } else if (activeIndex === 1) {
        const apsWithDuration = await getApsDuration();
        setAps(setApsColors(apsWithDuration, "avgTime"));
      } else if (activeIndex === 2) {
        getApsData().then(aps => setAps(setApsColors(aps)))
        getTrajectoryData().then(trajectory => setTrajectory(trajectory))
      }
    }
  }, [floor, dateRange.start, dateRange.end, activeIndex]);

  useEffect(() => {
    setAps((prev) => prev.map((ap) => ({ ...ap, color: "#42caff" })));
  }, [activeIndex]);


  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const canvas = canvasRef.current;
    const originalHeight = canvas.height;
    const originalWidth = canvas.width;
    const dimensions = getObjectFitSize(
      true,
      canvas.clientWidth,
      canvas.clientHeight,
      canvas.width,
      canvas.height
    );
    canvas.width = dimensions.width;
    canvas.height = dimensions.height;
    const ctx = canvas.getContext("2d");
    const ratio = Math.min(
      canvas.clientWidth / originalWidth,
      canvas.clientHeight / originalHeight
    );
    ctx.scale(ratio, ratio); //adjust this!
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (trajectory.length === 0) {
      return;
    }

    const firstAp = aps.find(ap => ap.macAddress?.toUpperCase() === trajectory[0]?.toUpperCase())
      ?? aps.find(ap => ap.macAddress?.toUpperCase() === trajectory[1]?.toUpperCase())
      ?? aps.find(ap => ap.macAddress?.toUpperCase() === trajectory[2]?.toUpperCase())

    ctx.moveTo(firstAp?.coordX * .6, firstAp?.coordY * .6 - 40);

    let i = 1
    ctx.font = "1rem Open Sans";
    for (const mac of trajectory) {
      const ap = aps.find(ap => ap.macAddress === mac)
      ctx.lineTo(ap?.coordX * .6, ap?.coordY * .6 - 40);
      ctx.fillText(i++, ap?.coordX * .6 - 60, ap?.coordY * .6 - 40)
    }

    ctx.lineWidth = 5;
    ctx.strokeStyle = '#ff0000';
    ctx.stroke();
  }, [trajectory]);

  function getObjectFitSize(
    contains /* true = contain, false = cover */,
    containerWidth,
    containerHeight,
    width,
    height
  ) {
    var doRatio = width / height;
    var cRatio = containerWidth / containerHeight;
    var targetWidth = 0;
    var targetHeight = 0;
    var test = contains ? doRatio > cRatio : doRatio < cRatio;

    if (test) {
      targetWidth = containerWidth;
      targetHeight = targetWidth / doRatio;
    } else {
      targetHeight = containerHeight;
      targetWidth = targetHeight * doRatio;
    }

    return {
      width: targetWidth,
      height: targetHeight,
      x: (containerWidth - targetWidth) / 2,
      y: (containerHeight - targetHeight) / 2,
    };
  }

  return (
    <>
      <NeoGridContainer>
        <NeoTitleSecond md={6} title="Mapas" icon={Icono} goback={"true"} />

        {/* <NeoButtonSection align="right">
          <NeoButtonMain
            label="Descarga reporte de visita"
            icon="pi pi-download"
          />
        </NeoButtonSection> */}
      </NeoGridContainer>

      <NeoGridContainer col={12}>
        <div className="heatmap-store">
          <h2>
            <span className="heatmap-store-name">Sucursal:</span>{" "}
            {storeDetail.STORE_NAME}
          </h2>
        </div>

        <NeoDropdown
          label="Piso"
          value={floor}
          onChange={(event) => {
            setFloor(event.value);
          }}
          options={floors}
          optionLabel="NAME"
        />
        <NeoCalendar
          col={2}
          label="Fecha de inicio"
          dateFormat="dd/mm/yy"
          value={dateRange.start}
          onChange={(event) => {
            setDateRange((prev) => ({ ...prev, start: event.value }));
          }}
          maxDate={dateRange.end}
          showTime
        />
        <NeoCalendar
          col={2}
          label="Fecha de fin"
          dateFormat="dd/mm/yy"
          value={dateRange.end}
          onChange={(event) =>
            setDateRange((prev) => ({ ...prev, end: event.value }))
          }
          minDate={dateRange.start}
          showTime
        />
      </NeoGridContainer>

      <NeoCard>
        <NeoTabMenu
          style={{ width: "100%", marginBottom: "1rem" }}
          model={[
            { label: "Conexiones" },
            { label: "Duración" },
            { label: "Trayectoría" },
          ]}
          activeIndex={activeIndex}
          onTabChange={(e) => {
            setActiveIndex(e.index);
          }}
        />

        <NeoTitleMain
          title={floor?.NAME ?? "Planta baja"}
        />
        <div className="heatmap-container p-col p-col-12" style={{minHeight: "40rem"}}>
          <ComponentFloorPlan floor={floor}/>
          <div className="heatmap-heat-points">

          {activeIndex === 0 && (
            <>
              {aps
                ?.slice()
                .sort((a, b) => b.coordX - a.coordX)
                .map((ap) => {
                  const background = `${ap?.background ?? "none"}`;
                  const border = `1px solid ${ap?.border ?? "none"}`;
                  return (

                    <div
                      className="ap-container"
                      key={ap.id}
                      style={{

                      }}
                    >
                      <div
                        className="ap"
                        style={{
                          top: `${(ap.coordY * 100) / 1080}%`,
                          left: `${(ap.coordX * 100) / 1980}%`,
                          background,
                          border,
                        }}
                      >
                      </div>
                      <div
                        className="ap-info"
                        style={{
                          top: `${(ap.coordY * 100) / 1080}%`,
                          left: `${(ap.coordX * 100) / 1980}%`,
                        }}
                      >
                        <p className="ap-location">{ap.label}</p>
                        <p className="ap-users">
                          {ap.connections} usuario
                          {ap.connections === 1 ? "" : "s"}
                        </p>
                      </div>
                    </div>
                  );
                })
              }
            </>
          )}

          {activeIndex === 1 && (
            <>
              {aps
                ?.slice()
                .sort((a, b) => b.coordX - a.coordX)
                .map((ap) => {

                  const background = `${ap.avgTime === 0 || !ap.avgTime ? "hsla(118, 34%, 45%, .5)" : (ap?.background ?? "none")}`;
                  const border = `1px solid ${ap.avgTime === 0 || !ap.avgTime ? "hsla(118, 34%, 45%, 1)" : (ap?.border ?? "none")}`;

                  return (
                    <div
                      className="ap-container"
                      key={ap.id}
                      style={{
                      }}
                    >
                      <div
                        className="ap"
                        style={{
                          top: `${(ap.coordY * 100) / 1080}%`,
                          left: `${(ap.coordX * 100) / 1980}%`,
                          background,
                          border,
                        }}
                      >
                      </div>
                      <div
                        className="ap-info"
                        style={{
                          top: `${(ap.coordY * 100) / 1080}%`,
                          left: `${(ap.coordX * 100) / 1980}%`,
                        }}
                      >
                        <p className="ap-location">{ap.label}</p>
                        <p className="ap-users">
                          {(() => {
                            const totalMinutes = Math.floor(
                              (ap.avgTime ?? 0) / 60
                            );

                            const seconds = Math.floor(
                              (ap.avgTime ?? 0) % 60
                            );
                            const hours = Math.floor(totalMinutes / 60);
                            const minutes = totalMinutes % 60;

                            return `${zeroPad(hours, 2)}:${zeroPad(
                              minutes,
                              2
                            )}:${zeroPad(seconds, 2)}`;
                          })()}
                        </p>
                      </div>
                    </div>
                  );
                })
              }
            </>
          )}

          {activeIndex === 2 && (
            <>
              {aps
                ?.slice()
                .sort((a, b) => b.coordX - a.coordX)
                .map((ap) => {

                  const background = `${ap?.background ?? "none"}`;
                  const border = `1px solid ${ap?.border ?? "none"}`;

                  return (
                    <div
                      className="ap-container"
                      key={ap.id}
                      style={{
                      }}>
                      <div
                        className="ap"
                        style={{
                          top: `${(ap.coordY * 100) / 1080}%`,
                          left: `${(ap.coordX * 100) / 1980}%`,
                          background,
                          border,
                        }}
                      >
                      </div>
                      <div
                        className="ap-info"
                        style={{
                          top: `${(ap.coordY * 100) / 1080}%`,
                          left: `${(ap.coordX * 100) / 1980}%`,
                        }}
                      >
                        <p className="ap-location">{ap.label}</p>
                        <p className="ap-users">
                          {ap.connections} usuario
                          {ap.connections === 1 ? "" : "s"}
                        </p>
                      </div>
                    </div>
                  );
                })
              }
            </>
          )}

        </div>
      </div>
      <div className="heatmap-bar-container">
        <div className="heatmap-bar" />
        <div class="heatmap-bar__symbols">
          <p>-</p>
          <p>+</p>
        </div>
      </div>
      </NeoCard>
    </>
  );
}
