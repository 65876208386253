import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getStoreDetail } from "../../../service/Store.service";
import { CONST } from "../../../consts/consts";
import useToastContext from "../../../hooks/useToastContext.hook";
import NeoCard from "../../../design/design_components/neo/panel/NeoCard.base";
import NeoColumn from "../../../design/design_components/neo/layout/NeoColumn.base";
import NeoDataSecond from "../../../design/design_components/neo/data/NeoDataSecond.base";
import NeoTable from "../../../design/design_components/neo/table/NeoTable.base";
import NeoTableColumn from "../../../design/design_components/neo/table/NeoTableColumn.base";
import NeoInputTextFilter from "../../../design/design_components/neo/table/NeoInputTextFilter.base";
import NeoMultiSelectFilter from "../../../design/design_components/neo/table/NeoMultiSelectFilter.base";
import NeoDialog from "../../../design/design_components/neo/overlay/NeoDialog.base";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import NeoButtonOutlined from "../../../design/design_components/neo/button/NeoButtonOutlined.base";
import NeoInputText from "../../../design/design_components/neo/form/NeoInputText.base";
import NeoSpinner from "../../../design/design_components/neo/overlay/NeoSpinner.base";

export default function StoreDetailContent() {
  const { id } = useParams();
  const [storeDetail, setStoreDetail] = useState({
    ID: 0,
    STORE_NAME: "",
    SSID: "",
    ADDRESS: "No disponible",
  });
  const [apStatusCollection, setApStatusCollection] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apConfigModalVisibility, setApConfigModalVisibility] = useState(false);
  const [modalInputApConfigModal, setModalInputApConfigModal] = useState("");
  const [aps, setAps] = useState([]);
  const dt = useRef(null);
  const toast = useToastContext();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getStoreDetailData();
    }

    return () => {
      mounted = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getStoreDetailData = async () => {
    setLoading(true);
    let storeData;
    storeData = await getStoreDetail(id);

    if (!storeData.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
      console.log(storeData);
    } else {
      setStoreDetail((prevState) => ({
        ...prevState,
        ...storeData.result.storeDetail[0],
      }));
      setAps(storeData.result.storeAp);
      setUpAvaibleStatus(storeData.result.storeAp);
      setLoading(false);
    }
  };

  const setUpAvaibleStatus = (aps) => {
    let status = [...new Set(aps.map((ap) => ap.STATUS))];
    setApStatusCollection(
      status.map((status) => ({ label: status, value: status }))
    );
  };

  const idBodyTemplate = (id) => {
    return (
      <>
        <span className="p-column-title"> Id</span>
        {id}
      </>
    );
  };

  const apNameBodyTemplate = (name) => {
    return (
      <>
        <span className="p-column-title">Nombre de dispositivo</span>
        {name}
      </>
    );
  };

  const macAddressBodyTemplate = (macAddress) => {
    return (
      <>
        <span className="p-column-title">Mac address</span>
        {macAddress}
      </>
    );
  };

  const modelBodyTemplate = (model) => {
    return (
      <>
        <span className="p-column-title">Modelo</span>
        {model}
      </>
    );
  };

  const tagsBodyTemplate = (tags) => {
    return (
      <>
        <span className="p-column-title">Tags</span>
        {tags?.join(', ')}
      </>
    );
  };

  const apStatusBodyTemplate = (apStatus) => {
    return (
      <>
        <span className="p-column-title">Estatus de dispositivo</span>
        {apStatus}
      </>
    );
  };

  const idFieldElement = () => {
    return (
      <NeoInputTextFilter
        ref={dt}
        field={"ID"}
        placeholder={"Buscar por id"}
        filterMatch={"contains"}
      />
    );
  };

  const apFieldElement = () => {
    return (
      <NeoInputTextFilter
        ref={dt}
        field={"AP_NAME"}
        placeholder={"Buscar por nombre"}
        filterMatch={"contains"}
      />
    );
  };

  const macAddresFieldElement = () => {
    return (
      <NeoInputTextFilter
        ref={dt}
        field={"MAC_ADDRESS"}
        placeholder={"Buscar por mac address"}
        filterMatch={"contains"}
      />
    );
  };

  const modelFieldElement = () => {
    return (
      <NeoInputTextFilter
        ref={dt}
        field={"MODEL"}
        placeholder={"Buscar por modelo"}
        filterMatch={"contains"}
      />
    );
  };

  const tagsFieldElement = () => {
    return (
      <NeoInputTextFilter
        ref={dt}
        field={"TAGS"}
        placeholder={"Buscar por tag"}
        filterMatch={"contains"}
      />
    );
  };

  const apStatusFieldElement = () => {
    return (
      <NeoMultiSelectFilter
        ref={dt}
        options={apStatusCollection}
        field={"STATUS"}
        placeholder="Todos"
        selectedItemsLabel="{0} tipos de estatus"
      />
    );
  };
  const handleSaveNewConfigAp = () => {
    if (modalInputApConfigModal === "") return;
    setApConfigModalVisibility(false);
  };
  const footerTemplateConfigApModal = (
    <>
      <NeoButtonOutlined
        label="Cancelar"
        onClick={() => setApConfigModalVisibility(false)}
      />
      <NeoButtonMain label="Guardar" onClick={handleSaveNewConfigAp} />
    </>
  );

  if (loading) {
    return <NeoSpinner />;
  }

  return (
    <>
      <NeoCard>
        <NeoColumn lg={4} col={12} md={6}>
          <NeoDataSecond fact={storeDetail.ID && storeDetail.ID} label="ID" />
        </NeoColumn>
        <NeoColumn lg={4} col={12} md={6}>
          <NeoDataSecond
            fact={storeDetail.STORE_NAME && storeDetail.STORE_NAME}
            label="Sucursal"
          />
        </NeoColumn>

        <NeoColumn lg={4} col={12} md={6}>
          <NeoDataSecond fact={storeDetail.SSID} label="SSID" />
        </NeoColumn>

        {/* <NeoColumn lg={12} col={12} md={12}>
          <NeoDataSecond fact={storeDetail.ADDRESS} label="Dirección" />
        </NeoColumn> */}
      </NeoCard>

      <NeoTable
        ref={dt}
        value={aps}
        loading={loading}
        paginator
        rows={5}
        autoLayout={true}
        emptyMessage="No hay dispositivos registrados"
        dataKey="ID"
        selectionMode="single"
        onRowSelect={(e) => {
          setModalInputApConfigModal(e.data.AP_NAME);
          setApConfigModalVisibility(true);
        }}
        removableSort
      >
        <NeoTableColumn
          field={"ID"}
          header={"ID"}
          body={(e) => idBodyTemplate(e.ID)}
          sortable
          filter
          filterElement={idFieldElement()}
        />
        <NeoTableColumn
          field={"AP_NAME"}
          header={"Nombre de dispositivo"}
          body={(e) => apNameBodyTemplate(e.AP_NAME)}
          sortable
          filter
          filterElement={apFieldElement()}
        />
        <NeoTableColumn
          field={"MAC_ADDRESS"}
          header={"Mac Address"}
          body={(e) => macAddressBodyTemplate(e.MAC_ADDRESS)}
          sortable
          filter
          filterElement={macAddresFieldElement()}
        />
        <NeoTableColumn
          field={"MODEL"}
          header={"Modelo"}
          body={(e) => modelBodyTemplate(e.MODEL)}
          sortable
          filter
          filterElement={modelFieldElement()}
        />
        <NeoTableColumn
          field={"TAGS"}
          header={"Tags"}
          body={(e) => tagsBodyTemplate(e.TAGS)}
          sortable
          filter
          filterElement={tagsFieldElement()}
        />
        <NeoTableColumn
          field={"STATUS"}
          header={"Estatus"}
          body={(e) => apStatusBodyTemplate(e.STATUS)}
          sortable
          filter
          filterElement={apStatusFieldElement()}
        />
      </NeoTable>
      <NeoDialog
        header="Configuración AP"
        footer={footerTemplateConfigApModal}
        visible={apConfigModalVisibility}
        closable={false}
      >
        <NeoInputText
          label="Nombre de dispositivo"
          value={modalInputApConfigModal}
          onChange={(e) => setModalInputApConfigModal(e.target.value)}
        />
      </NeoDialog>
    </>
  );
}
