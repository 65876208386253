
import { useLookerUrl } from "../../hooks/useLookerUrl.hook";

const GeneralView = () => {
  const { url } = useLookerUrl("neowif_test::dashboard_principal")
  return (
    
        <iframe
          title="Dashbaord General"
          src={url}
          allowFullScreen
        />
      
  )
}

export default GeneralView;
