import React from 'react';
import StoresTable from './components/StoresTable.component';
import NeoGridContainer from '../../design/design_components/neo/layout/NeoGridContainer.base'
import NeoTitleMain from '../../design/design_components/neo/title/NeoTitleMain.base'
import Icono from '../../design/assets/img/wfi/icons/icon-branch-3.svg';

export default function Store() {
  return (
    <>
      <NeoGridContainer>
        <NeoTitleMain title="Sucursales" icon={Icono} />
      </NeoGridContainer>

      <StoresTable />
    </>
  );
}
