import React from "react";
import NeoButtonSection from "../../design/design_components/neo/layout/NeoButtonSection.base";
import NeoColumn from "../../design/design_components/neo/layout/NeoColumn.base";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleSecond from "../../design/design_components/neo/title/NeoTitleSecond.base";
import BlackListButton from "./components/BlackListButton.component";
import ContactDetailContent from "./components/ContactDetailContent.component";
import RevalidateContactButton from "./components/RevalidateContactButton.component";
import Icono from '../../design/assets/img/wfi/icons/icon-contacts.svg';

export default function ContactDetails({ location }) {
  return (
    <>
      <NeoGridContainer>
        <NeoTitleSecond
          title="Detalle de contacto"
          icon={Icono}
          goback={"true"}
        />

        <NeoButtonSection align="right">
          <RevalidateContactButton />



          <BlackListButton contactData={location.state} />
        </NeoButtonSection>

      </NeoGridContainer>
      <ContactDetailContent contactData={location.state} />
    </>
  );
}
