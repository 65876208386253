import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import NeoColumn from "../../design/design_components/neo/layout/NeoColumn.base";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoSingleContainer from "../../design/design_components/neo/layout/NeoSingleContainer.base";
import NeoCard from "../../design/design_components/neo/panel/NeoCard.base";
import NeoInnerTitle from "../../design/design_components/neo/title/NeoInnerTitle.base";
import {
  sendReview,
  sendReviewCommentOpinion,
} from "../../service/Mail.service";
import NeoRating from "../../design/design_components/neo/form/NeoRating.base";
import NeoInputTextarea from "../../design/design_components/neo/form/NeoInputTextarea.base";
import NeoButtonMain from "../../design/design_components/neo/button/NeoButtonMain.base";

export default function RecieveReview() {
  const { visitId, value } = useParams();
  const [loading, setLoading] = useState(false);
  const [element, setElement] = useState(null);
  const [opinion, setOpinion] = useState(null);
  const history = useHistory();

  useEffect(() => {
    let mount = true;
    setLoading(true);
    if (visitId && value) {
      sendReviewService();
      setLoading(false);
    }
    return () => {
      mount = false;
    };
  }, []);

  const renderRating = (post) => {
    if (post) {
      return (
        <>
          <p>¡Muchas gracias por responder!</p>
          <NeoRating value={value} readOnly stars={5} cancel={false} />
          <NeoInputTextarea
            value={opinion}
            placeholder={"Danos tu opinión"}
            onChange={(e) => setOpinion(e.target.value)}
          />

          <NeoButtonMain
            label="Enviar comentarios"
            onClick={sendReviewComment}
          />
        </>
      );
    } else {
      return <p>Ya has respondido. ¡Muchas gracias!</p>;
    }
  };

  const sendReviewComment = async () => {
    await sendReviewCommentOpinion({ visitId: visitId, message: opinion });
    history.go(0);
  };

  const sendReviewService = async () => {
    let result = await sendReview({ visitId: visitId, value: value });
    setElement(renderRating(result.success));
  };

  return (
    <NeoSingleContainer>
      <NeoGridContainer col="12" subExtra="h-100">
        <NeoColumn col="12" extra="px-0"></NeoColumn>
        <NeoColumn md="6" extra="p-md-offset-3 ">
          <NeoCard extra="text-align-center">
            <NeoColumn extra="px-0">
              <NeoInnerTitle extra="primero">Envío de encuesta</NeoInnerTitle>
              {loading ? (
                <i
                  className="pi pi-spin pi-spinner"
                  style={{ fontSize: "2em" }}
                ></i>
              ) : (
                element
              )}
            </NeoColumn>
          </NeoCard>
        </NeoColumn>
      </NeoGridContainer>{" "}
    </NeoSingleContainer>
  );
}
